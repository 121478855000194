













































import NoData from "@/components/common/NoData.vue";
import AppDialog from "@/components/Navigation/AppDialog.vue";
import AuthCarousel from "@/components/users/AuthCarousel.vue";
import { WatchLoading } from "@/decorators/Loading";
import { LoadingMixin } from "@/mixins/Helpers";
import Component, { mixins } from "vue-class-component";

@Component({
  name: "register",
  components: {
    NoData,
    AppDialog,
    AuthCarousel
  }
})
export default class Register extends mixins(LoadingMixin) {
  @WatchLoading("global")
  mounted() {
    return null;
  }

  get directRegistrationAllowed(): boolean {
    return this.$store.getters.featureFlags["direct-registration"];
  }

  get showSecondaryDialog(): boolean {
    return (
      this.$route.fullPath.includes("coaches") ||
      this.$route.fullPath.includes("player")
    );
  }
}
